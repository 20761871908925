$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

.icon-warning-sign {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-exclamation-circle;
  color: red;
  font-size: 20px;;
}

.icon-notch {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-circle-notch;
  @extend .fa-spin;
}

.icon-bars {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-bars;
}

.icon-play {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-play;
  // @extend .fa-spin;
}

.icon-stop {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-stop;
  // @extend .fa-spin;
}

.icon-arrow-up {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-arrow-up ;
}

.icon-arrow-down {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-arrow-down ;
}

.icon-chevron-left {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-chevron-left ;
}

.icon-chevron-right {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-chevron-right ;
}

.icon-spinner {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-spinner;
  @extend .fa-spin;
}

.icon-save {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-save;
}

.icon-loading {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-spinner;
  @extend .fa-spin;
}

.icon-circle-plus {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-plus-circle;
}

.icon-circle {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-circle;
}

.icon-arrow {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-arrow-circle-right;
}

.icon-trash {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-trash;
}

.icon-search {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-search;
}

.icon-more {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-info-circle;
}

.icon-enveloppe {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-envelope;
}

.icon-phone {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-phone;
}

.icon-apple {
  @extend %fa-icon;
  @extend .fab;
  @extend .fa-apple;
}

.icon-android {
  @extend %fa-icon;
  @extend .fab;
  @extend .fa-android;
}

.icon-linkedin {
  @extend %fa-icon;
  @extend .fab;
  @extend .fa-linkedin;
}

.icon-ellipsis {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-ellipsis-h;
}

.icon-wifi {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-wifi;
}

.icon-random {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-random;
}

.icon-cloud {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-cloud;
}