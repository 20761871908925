@mixin collapse($minHeight) {
  .collapse-container {
    transition: max-height 400ms ease;
    max-height: 0px;
    overflow: hidden;

    &.collapse-visible {
      visibility: visible;
      max-height: $minHeight;
    }
  }
}
