// @import "~app/components/common/icons";
// @import "~bootstrap/scss/bootstrap";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
// @import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";
// @import "./mdb.scss";

// @import "./collapse.scss";


@mixin fontFamily {
  font-family: "Lato", sans-serif;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Lato Light"), local("Lato-Light"), url(~static/fonts/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Lato Light"), local("Lato-Light"), url(~static/fonts/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap; //     url(~app/static/fonts/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2')
  // src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2');
  src: local("Lato Regular"), local("Lato-Regular"), url(~static/fonts/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  // src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
  src: local("Lato Regular"), local("Lato-Regular"), url(~static/fonts/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@mixin font-mixin {
  @include fontFamily;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 1.5;
  color: white;
}

body {
  cursor: auto;
  @include font-mixin;
  // font-family: "Lato Regular", sans-serif!important;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: black;
}
.list-group-item {
  background-color: #191616;
}

a {
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.card {
  background-color: #222;

  .card-header {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.table {
  tr {
    th {
      @include font-mixin;
    }
    td {
      @include font-mixin;
    }
  }
}

.input-group {
  padding: 5px 5px;

  &:last-of-type {
    padding-bottom: 10px;
  }
}

h1,
h2,
h3,
h4 {
  @include fontFamily;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

.cursor-pointer {
  cursor: pointer;
}

.height-100p {
  height: 100% !important;
}

// .main-container {
//   // padding-top: 20px;
// }

// $screen-sm-min : 500px;

button {
  @extend .btn;
  @extend .btn-dark;
}

.app-container {
  .logo {
    position: relative;
    padding-top: 25px;
    padding-left: 120px;
    // padding-left: 90px;
    padding-bottom: 30px;
    z-index: 1000;

    img {
      width: 180px;
    }
  }
  .app-content {
    min-height: 100vh;
    min-width: 100vw;
    padding-bottom: 80px;
  }
}

.backoffice-container {
  padding-top: 0px;

  .breadcrumb {
    margin-bottom: 30px;
  }
}
