$banner_width: 360px;
$item_margin: 25px;
$break-large: 1024px;

.menu-container {
  h2 {
    padding-top: 10px;
  }

  h3 {
    font-size: 16px;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 150px;
    }
  }
  ul {
    padding-top: 15px;
    cursor: default;

    li {
      list-style: none;
      padding: 3px;
    }

    span {
      cursor: pointer;

    }
  }

  .menu-button {
    position: absolute;
    top: 20px;
    left: 30px;
    // color: #373a47;
    color: white;
    font-size: 42px;
    cursor: pointer;
    z-index: 1500;
  }

  .banner {
    position: absolute;
    margin: 0;
    top: 0;
    left: -400px;
    height: 100%;
    width: $banner_width;
    background-color: #1b1c1d;
    // background-color: #373a47;
    color: white;
    border: 0px black solid;
    transition: left 300ms ease-in-out;
    overflow: hidden;
    text-align: left;
    z-index: 900;
    padding-top: 100px;
    // padding: 100px 0 0 80px;
    font-size: 24px;

    .content {
      overflow: scroll;
      height: 100vh;
      padding-bottom: 80px;

      .footer-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 80px;
        width: 100%;
        background-color: #1c1c1d;
        z-index: 5000;

        .footer {
          // position: fixed;
          bottom: 0;
          padding: 15px;
          font-size: 15px;
        }
      }
    }

    &.visible {
      left: 0px;

      // @media screen and (max-width: $break-large) {
      //   background-color: rgba(0, 0, 0, 1);
      // }

      // @media screen and (max-width: $banner_width) {
      //   width: 100%;
      //   background-color: rgba(0, 0, 0, 1);
      // }
    }
  }

  .list {
    padding-top: 10px;
    width: 100%;
    height: 100%;
  }
}
